import React from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Red } from './common'

const InfoHeader = styled.h1`
  color: #f2f2f2;
  font-family: Raleway;
  text-align: left;
  font-size: calc(12px + (30 - 12) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1em + (1.5 - 1) * ((100vw - 300px) / (1600 - 300)));
  font-weight: bold;
  margin-bottom: 3px;
`
const SubHeading = styled.h1`
  color: #bb2d31;
  font-size: calc(10px + (16 - 10) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1em + (1.5 - 1) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: 6px;
`
const Center = styled(Grid)`
  margin: Auto;
  width: 90%;
  display: flex;
`
const RightInfo = styled.div`
    width: 82%;
    text-align: left;
    height: auto;

    color: #F2F2F2;
    font-size: calc(10px + (24 - 10) * ((100vw - 300px) / (1600 - 300)));
    line-height: calc(1em + (1.5 - 1) * ((100vw - 300px) / (1600 - 300)));
    font-weight: bold;
    display: inline-block;
    margin-left: calc(30px + (100 - 30) * ((100vw - 300px) / (1600 - 300)));
    margin-top: auto;
`
const Date = styled.span`
  color: #74b9ff;
  font-size: calc(12px + (24 - 12) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1em + (1.5 - 1) * ((100vw - 300px) / (1600 - 300)));
`
const MoreInfo = styled.div`
  color: #f2f2f2;
  width: 100px;

  padding-top: 15%;
`
const StyledLink = styled.a`
  font-family: Raleway;
  text-align: right;
  font-size: calc(10px + (14 - 10) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1em + (1.5 - 1) * ((100vw - 300px) / (1600 - 300)));
  font-weight: normal;

  &:visited,
  &:hover,
  &:active,
  &:link {
    color: #74b9ff;
    text-decoration: none;
  }
`

const News = ({ event }) => {
  const {
    body,
    frontmatter: { title, date, subheading },
    fields: { slug },
  } = event

  return (
    <>
      <Center>
        <RightInfo>
          <InfoHeader>
            {title} <br/> <Date>{date}</Date>
          </InfoHeader>
          <SubHeading>{subheading}</SubHeading>
          {body && <MDXRenderer>{body}</MDXRenderer>}
        </RightInfo>
      </Center>
      <MoreInfo>
        {slug && (
          <StyledLink href={slug}>
              More Info<Red> &gt; </Red>
          </StyledLink>
        )}
      </MoreInfo>
    </>
  )
}

export default News
