import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Grid from "@material-ui/core/Grid"
import ParishPhoto from "../images/parish_photo.jpg"
import PageHeader from "../components/PageHeader"
import NewsStory from "../components/NewsStory"
import SEO from "../components/seo"
import { PageHeaderText } from '../components/common'

const TitleImage = styled(Grid)`
  height: 35vh;
  width: 100%;
  background: linear-gradient(
      180deg,
      rgba(10, 10, 10, 0.61) 35.42%,
      rgba(51, 121, 200, 0.85) 99.48%
    ),
    url(${ParishPhoto});
  background-size: cover;
  display: flex;
`
const InfoContainer1 = styled(Grid)`
  background: #0d0d0d;
  width: 100%;
  display: flex;
  position: relative;
  padding-top: 10px;
`
const InfoContainer2 = styled(Grid)`
  background: #181818;
  width: 100%;
  display: flex;
  position: Relative;
  padding-top: 10px;
`

const News = ({ data }) => {
  const events =
    data &&
    data.allMdx.nodes.filter(event => event.frontmatter.path === "/event")

  return (
      <Grid container>
        <SEO title="News" />
        <PageHeader />
        <TitleImage item xs={12}>
          <PageHeaderText>What's Happening?</PageHeaderText>
        </TitleImage>
        {events &&
          events.map((event, index) => {
            return index % 2 === 0 ? (
              <InfoContainer1 key={`news-item-${index}`} item xs={12}>
                <NewsStory event={event} />
              </InfoContainer1>
            ) : (
              <InfoContainer2 key={`news-item-${index}`} item xs={12}>
                <NewsStory event={event} />
              </InfoContainer2>
            )
          })}
      </Grid>
  )
}

export const query = graphql`
  query newsQuery {
    allMdx(sort: { order: DESC, fields: frontmatter___date }) {
      nodes {
        body
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          name
          title
          path
          featuredImage
        }
        fields {
          slug
        }
      }
    }
  }
`

export default News
